@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .btnButton{
        @apply bg-white max-sm:py-3 max-sm:px-3 max-sm:text-sm mt-10 sm:px-3 sm:py-2 lg:px-5 lg:py-3 flex justify-center items-center text-black sm:text-lg lg:text-lg font-bold border-0 rounded-full
    }
    .whiteHover{
        @apply  hover:outline-2 hover:outline-white hover:outline hover:text-white hover:bg-black
    }
    .blackHover{
        @apply hover:outline-2 hover:outline-black hover:outline hover:text-black hover:bg-white
    }
    .overlay{
        @apply w-full h-full absolute hover:bg-black  hover:opacity-80  hover:text-white  hover:font-semibold  hover:flex  hover:items-center  hover:justify-center hover:cursor-pointer
    }
}